import { recoilPersist } from "recoil-persist";

export default function implementPersist(key: any) {
  const { persistAtom } = recoilPersist({
    key,
    storage: localStorage,
  });

  return [persistAtom];
}
